import { useState } from 'react';
import Input from "./Input";
import { loginFields } from '../constant/formFields';
import FormExtra from './FormExtra';
import FormAction from './FormAction';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import handleErrorResponse from '../utils/handleErrorResponse';
import networkRequest from '../axios-config/axiosInstance';
import endpoints from '../axios-config/endpoints';
import MiniLoader from './MiniLoader';
import { useDispatch } from 'react-redux';
import { setRefreshToken, setToken } from '../redux/slice/authSlice';
import { fetchChatBotQuestions } from '../redux/slice/chatBotSlice';
type fieldsStateType = {
    [key: string]: string
}
const fields = loginFields;
let fieldsState: fieldsStateType = {};
fields.forEach(field => fieldsState[field.id] = '');

export default function Login() {
    const [loginState, setLoginState] = useState(fieldsState);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleChange = (e: { target: { id: any; value: any; }; }) => {
        setLoginState({ ...loginState, [e.target.id]: e.target.value })
    }

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        navigate('/home')
        return
        await authenticateUser();
    }

    //Handled Login API Integration here
    const authenticateUser = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("email", loginState.email_address)
            formData.append("password", loginState.password)
            const response = await networkRequest({}).post(endpoints.login, formData)
            console.log(response)
            console.log(response.status)
            if (response?.data?.status === 'success') {
                setLoading(false)
                toast.success(response?.data?.message)
                dispatch(setToken(response?.data?.access))
                dispatch(setRefreshToken(response?.data?.refresh))
                navigate('/home')
            }
            setLoading(false)

        } catch (error: any) {
            setLoading(false)
            console.log(error)
            const errorMsg = handleErrorResponse(error)
            toast.error(errorMsg)

        }
    }

    return (
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="-space-y-px">
                {
                    fields.map(field =>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                        />

                    )
                }
            </div>

            <FormExtra />
            <FormAction handleSubmit={handleSubmit} text={loading ? <MiniLoader /> : "Login"} />

        </form>
    )
}